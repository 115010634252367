/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as haKodeproV1000 from '../../../ha/kodepro/v1/common.pb';
/**
 * Message implementation for ha.kodepro.v1.WorkspaceSnapshotRequest
 */
export class WorkspaceSnapshotRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceSnapshotRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceSnapshotRequest();
    WorkspaceSnapshotRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceSnapshotRequest) {
    _instance.workspaceId = _instance.workspaceId || '';
    _instance.snapshotId = _instance.snapshotId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceSnapshotRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.workspaceId = _reader.readString();
          break;
        case 2:
          _instance.snapshotId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceSnapshotRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceSnapshotRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.workspaceId) {
      _writer.writeString(1, _instance.workspaceId);
    }
    if (_instance.snapshotId) {
      _writer.writeString(2, _instance.snapshotId);
    }
  }

  private _workspaceId?: string;
  private _snapshotId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceSnapshotRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceSnapshotRequest.AsObject>) {
    _value = _value || {};
    this.workspaceId = _value.workspaceId;
    this.snapshotId = _value.snapshotId;
    WorkspaceSnapshotRequest.refineValues(this);
  }
  get workspaceId(): string | undefined {
    return this._workspaceId;
  }
  set workspaceId(value: string | undefined) {
    this._workspaceId = value;
  }
  get snapshotId(): string | undefined {
    return this._snapshotId;
  }
  set snapshotId(value: string | undefined) {
    this._snapshotId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceSnapshotRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceSnapshotRequest.AsObject {
    return {
      workspaceId: this.workspaceId,
      snapshotId: this.snapshotId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceSnapshotRequest.AsProtobufJSON {
    return {
      workspaceId: this.workspaceId,
      snapshotId: this.snapshotId
    };
  }
}
export module WorkspaceSnapshotRequest {
  /**
   * Standard JavaScript object representation for WorkspaceSnapshotRequest
   */
  export interface AsObject {
    workspaceId?: string;
    snapshotId?: string;
  }

  /**
   * Protobuf JSON representation for WorkspaceSnapshotRequest
   */
  export interface AsProtobufJSON {
    workspaceId?: string;
    snapshotId?: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceSnapshotResponse
 */
export class WorkspaceSnapshotResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceSnapshotResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceSnapshotResponse();
    WorkspaceSnapshotResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceSnapshotResponse) {
    _instance.workspaceId = _instance.workspaceId || '';
    _instance.snapshotId = _instance.snapshotId || '';
    _instance.status = _instance.status || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceSnapshotResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.workspaceId = _reader.readString();
          break;
        case 2:
          _instance.snapshotId = _reader.readString();
          break;
        case 3:
          _instance.status = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceSnapshotResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceSnapshotResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.workspaceId) {
      _writer.writeString(1, _instance.workspaceId);
    }
    if (_instance.snapshotId) {
      _writer.writeString(2, _instance.snapshotId);
    }
    if (_instance.status) {
      _writer.writeString(3, _instance.status);
    }
  }

  private _workspaceId?: string;
  private _snapshotId?: string;
  private _status?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceSnapshotResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceSnapshotResponse.AsObject>) {
    _value = _value || {};
    this.workspaceId = _value.workspaceId;
    this.snapshotId = _value.snapshotId;
    this.status = _value.status;
    WorkspaceSnapshotResponse.refineValues(this);
  }
  get workspaceId(): string | undefined {
    return this._workspaceId;
  }
  set workspaceId(value: string | undefined) {
    this._workspaceId = value;
  }
  get snapshotId(): string | undefined {
    return this._snapshotId;
  }
  set snapshotId(value: string | undefined) {
    this._snapshotId = value;
  }
  get status(): string | undefined {
    return this._status;
  }
  set status(value: string | undefined) {
    this._status = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceSnapshotResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceSnapshotResponse.AsObject {
    return {
      workspaceId: this.workspaceId,
      snapshotId: this.snapshotId,
      status: this.status
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceSnapshotResponse.AsProtobufJSON {
    return {
      workspaceId: this.workspaceId,
      snapshotId: this.snapshotId,
      status: this.status
    };
  }
}
export module WorkspaceSnapshotResponse {
  /**
   * Standard JavaScript object representation for WorkspaceSnapshotResponse
   */
  export interface AsObject {
    workspaceId?: string;
    snapshotId?: string;
    status?: string;
  }

  /**
   * Protobuf JSON representation for WorkspaceSnapshotResponse
   */
  export interface AsProtobufJSON {
    workspaceId?: string;
    snapshotId?: string;
    status?: string;
  }
}
