/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';

/**
 * Message implementation for ha.kodepro.v1.AccessControl
 */
export class AccessControl implements GrpcMessage {
  static id = 'ha.kodepro.v1.AccessControl';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AccessControl();
    AccessControl.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AccessControl) {
    _instance.locked = _instance.locked || false;
    _instance.owner = _instance.owner || '';
    _instance.userRoles = _instance.userRoles || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AccessControl,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.locked = _reader.readBool();
          break;
        case 2:
          _instance.owner = _reader.readString();
          break;
        case 3:
          const messageInitializer3 = new UserRole();
          _reader.readMessage(
            messageInitializer3,
            UserRole.deserializeBinaryFromReader
          );
          (_instance.userRoles = _instance.userRoles || []).push(
            messageInitializer3
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AccessControl.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AccessControl,
    _writer: BinaryWriter
  ) {
    if (_instance.locked) {
      _writer.writeBool(1, _instance.locked);
    }
    if (_instance.owner) {
      _writer.writeString(2, _instance.owner);
    }
    if (_instance.userRoles && _instance.userRoles.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.userRoles as any,
        UserRole.serializeBinaryToWriter
      );
    }
  }

  private _locked?: boolean;
  private _owner?: string;
  private _userRoles?: UserRole[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AccessControl to deeply clone from
   */
  constructor(_value?: RecursivePartial<AccessControl.AsObject>) {
    _value = _value || {};
    this.locked = _value.locked;
    this.owner = _value.owner;
    this.userRoles = (_value.userRoles || []).map(m => new UserRole(m));
    AccessControl.refineValues(this);
  }
  get locked(): boolean | undefined {
    return this._locked;
  }
  set locked(value: boolean | undefined) {
    this._locked = value;
  }
  get owner(): string | undefined {
    return this._owner;
  }
  set owner(value: string | undefined) {
    this._owner = value;
  }
  get userRoles(): UserRole[] | undefined {
    return this._userRoles;
  }
  set userRoles(value: UserRole[] | undefined) {
    this._userRoles = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AccessControl.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AccessControl.AsObject {
    return {
      locked: this.locked,
      owner: this.owner,
      userRoles: (this.userRoles || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AccessControl.AsProtobufJSON {
    return {
      locked: this.locked,
      owner: this.owner,
      userRoles: (this.userRoles || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module AccessControl {
  /**
   * Standard JavaScript object representation for AccessControl
   */
  export interface AsObject {
    locked?: boolean;
    owner?: string;
    userRoles?: UserRole.AsObject[];
  }

  /**
   * Protobuf JSON representation for AccessControl
   */
  export interface AsProtobufJSON {
    locked?: boolean;
    owner?: string;
    userRoles?: UserRole.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.UserRole
 */
export class UserRole implements GrpcMessage {
  static id = 'ha.kodepro.v1.UserRole';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserRole();
    UserRole.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserRole) {
    _instance.username = _instance.username || '';
    _instance.role = _instance.role || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserRole,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.username = _reader.readString();
          break;
        case 2:
          _instance.role = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UserRole.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: UserRole, _writer: BinaryWriter) {
    if (_instance.username) {
      _writer.writeString(1, _instance.username);
    }
    if (_instance.role) {
      _writer.writeString(2, _instance.role);
    }
  }

  private _username?: string;
  private _role?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserRole to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserRole.AsObject>) {
    _value = _value || {};
    this.username = _value.username;
    this.role = _value.role;
    UserRole.refineValues(this);
  }
  get username(): string | undefined {
    return this._username;
  }
  set username(value: string | undefined) {
    this._username = value;
  }
  get role(): string | undefined {
    return this._role;
  }
  set role(value: string | undefined) {
    this._role = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserRole.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserRole.AsObject {
    return {
      username: this.username,
      role: this.role
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserRole.AsProtobufJSON {
    return {
      username: this.username,
      role: this.role
    };
  }
}
export module UserRole {
  /**
   * Standard JavaScript object representation for UserRole
   */
  export interface AsObject {
    username?: string;
    role?: string;
  }

  /**
   * Protobuf JSON representation for UserRole
   */
  export interface AsProtobufJSON {
    username?: string;
    role?: string;
  }
}
